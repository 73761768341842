.main {
  height: 100vh;
  width: 100vw;
  background-color: #111834;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  p {
    margin: 8px 0;
  }
}